import React from "react"
import tw, { styled } from "twin.macro"

import { graphql } from "gatsby"
import Img from "gatsby-image"

import get from "lodash/get"

import { getPageContent } from "../util"
import Layout from "../components/Layout"
import Section from "../components/Section"
import Button from "../components/Button"
import Title from "../components/Title"

import bgVidWebm from "../images/intro.webm"
import bgVidMp4 from "../images/intro.mp4"

const StyledTitle = tw(Title)`
  py-8
`

const BannerBox = tw.div`
  w-full
  pt-12
  flex flex-row justify-center items-center
`

const BannerLink = styled.a`
  ${tw`inline-block w-full`}
  max-width: 1280px;
`

const BannerNormal = styled.a`
  ${tw`inline-block w-full`}
  max-width: 1280px;
`

const CallToActionBox = tw.div`
  text-center h-screen
  flex flex-col justify-center
`

const MicheleAutoContainer = tw.div`
  w-full h-screen
  flex flex-col justify-center items-center
`

export default ({ data }) => {
  const bg1 = get(data, "bg_1.childImageSharp.fluid")
  const bg2 = get(data, "bg_2.childImageSharp.fluid")
  const bg3 = get(data, "bg_3.childImageSharp.fluid")
  const micheleAuto = get(data, "micheleAuto.childImageSharp.fixed")
  const pageContent = getPageContent(data)
  const banner = get(pageContent, "banner")
  const bannerLink = get(pageContent, "bannerLink")

  const bannerImg = <Img fluid={banner} />

  const bannerComp = (bannerLink === '') ? (
    <BannerNormal>{bannerImg}</BannerNormal>
  ) : (
    <BannerLink href={bannerLink} target="_blank" rel="noreferrer">
      {bannerImg}
    </BannerLink>
  )

  return (
    <Layout>
      <Section bgImage={bg1} bgVideo={[
        {
          type: "webm",
          source: bgVidWebm,
        },
        {
          type: "mp4",
          source: bgVidMp4,
        },
      ]} blackFilter isFirst />
      {banner !== "dummy" && (
        <BannerBox>{bannerComp}</BannerBox>
      )}
      <Section bgImage={bg2} contentAt="right">
        <CallToActionBox>
          <StyledTitle>{pageContent.slogan}</StyledTitle>
          <Button to="/contact_us">{pageContent.callToAction}</Button>
        </CallToActionBox>
      </Section>
      <Section bgImage={bg3} blackFilter>
        <MicheleAutoContainer>
          <Img fixed={micheleAuto} />
        </MicheleAutoContainer>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery($path: String) {
    bg_1: file(relativePath: { eq: "images/bg_1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bg_2: file(relativePath: { eq: "images/bg_2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bg_3: file(relativePath: { eq: "images/bg_3.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    micheleAuto: file(relativePath: { eq: "images/michele_auto.png" }) {
      childImageSharp {
        fixed(width: 180) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    sitePage(path: { eq: $path }) {
      contentFile {
        childContentJson {
          callToAction
          slogan
          banner
          bannerLink

          allImageFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            relativePath
          }
        }
      }
    }
  }
`
