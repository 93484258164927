import React from "react"
import tw from "twin.macro"

import get from "lodash/get"

import { graphql } from "gatsby"
import Img from "gatsby-image"

import { getPageContent } from "../util"
import ContactForm from "../components/ContactForm"
import Layout from "../components/Layout"
import Section from "../components/Section"
import SquareTitle from "../components/SquareTitle"
import Box from "../components/Box"

const Container = tw.div`
  flex flex-col items-center pt-32
`

const Content = tw.div`
  flex flex-col items-stretch
`

const MapTitle = tw(SquareTitle)`
  uppercase mt-16
`

const Map = tw(Img)`
  mt-2
  border-2 border-primary
`

const WechatBox= tw(Box)`
  mt-8
`

export default function ContactUsPage({ data }) {
  const map = get(data, "map.childImageSharp.fixed")
  const bg = get(data, "bg.childImageSharp.fluid")

  const pageContent = getPageContent(data)

  return (
    <Layout>
      <Section bgImage={bg} blackFilter isFirst>
        <Container>
          <Content>
            <ContactForm />
            <MapTitle>{pageContent.mapTitle}</MapTitle>
            <a href="https://www.amap.com/place/B00157HETN" target="_blank" rel="noreferrer">
              <Map fixed={map} />
            </a>
            <WechatBox>
              <Box.Title>{pageContent.wechatTitle}</Box.Title>
              <Box.Content>{pageContent.wechatAccount}</Box.Content>
            </WechatBox>
          </Content>
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactUsQuery($path: String) {
    bg: file(relativePath: { eq: "images/bg_contact.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    map: file(relativePath: { eq: "images/map.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    sitePage(path: { eq: $path }) {
      contentFile {
        childContentJson {
          mapTitle
          wechatTitle
          wechatAccount

          allImageFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            relativePath
          }
        }
      }
    }
  }
`
