import React from "react"
import get from "lodash/get"

import tw, { styled } from "twin.macro"

import { useStaticQuery, graphql } from "gatsby"
import { Link, FormattedMessage } from "gatsby-plugin-intl"

import { Facebook, Weibo, Instagram, Linkedin } from "./Icons"
import Logo from "./Logo"

const Container = tw.div`
  bg-primary
  w-full
  flex flex-row flex-wrap items-start
  pb-16
`

const Cell = tw.div`
  w-full lg:w-1/2
  px-16
  pt-16
`

const LeftCell = styled(Cell)`
  ${tw`hidden lg:block`}

  &>* {
    ${tw`float-left`}
  }
`

const RightCell = styled(Cell)`
  ${tw`text-center`}
  &>* {
    ${tw`float-none lg:float-right inline-block`}
  }
`

const LogoContainer = tw.div`
  w-32
`

const SocialsContainer = styled.div`
  ${tw`inline-flex flex-row`}
  &>* {
    ${tw`mx-4`}
  }
`

const MenuContainer = tw.div`
  flex flex-col
  text-xs text-white uppercase
  font-bold
`

const ContactContainer = tw.div`
  flex flex-col
  text-center lg:text-right
  text-xs text-white
  font-bold
`

export default function Footer(props) {
  const data = useStaticQuery(graphql`
    query SocialLinks {
      site {
        siteMetadata {
          socialLinks {
            facebook
            instagram
            linkedin
            weibo
          }
          contact {
            title
            wechat
            address_en_line1
            address_en_line2
            address_zh
            number
            email
          }
        }
      }
    }
  `)

  const { facebook, instagram, linkedin, weibo } = get(
    data,
    "site.siteMetadata.socialLinks",
    {}
  )

  const contact = get(data, "site.siteMetadata.contact", {})

  const { items } = props

  return (
    <Container>
      <LeftCell>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </LeftCell>
      <RightCell>
        <SocialsContainer>
          <a aria-label="facebook" href={facebook}>
            <Facebook />
          </a>
          <a aria-label="instagram" href={instagram}>
            <Instagram />
          </a>
          <a aria-label="linkedin" href={linkedin}>
            <Linkedin />
          </a>
          <a aria-label="weibo" href={weibo}>
            <Weibo />
          </a>
        </SocialsContainer>
      </RightCell>
      <LeftCell>
        <MenuContainer>
          {items.map((item) => (
            <Link key={item.name} to={item.link}>
              <FormattedMessage id={item.name} />
            </Link>
          ))}
        </MenuContainer>
      </LeftCell>
      <RightCell>
        <ContactContainer>
          <div>{contact.address_en_line1}</div>
          <div>{contact.address_en_line2}</div>
          <div>{contact.address_zh}</div>
          <div>&nbsp;</div>
          <div>
            {contact.number} {contact.email}
          </div>
          <div>
            <FormattedMessage id="contact_wechat" />: {contact.wechat}
          </div>
          <div>&nbsp;</div>
          <div>&#169;2020 {contact.title}</div>
          <div>沪ICP备19025123号</div>
        </ContactContainer>
      </RightCell>
    </Container>
  )
}
