import React from "react"
import get from "lodash/get"
import Helmet from "react-helmet"

import { useStaticQuery, graphql } from "gatsby"

import tw from "twin.macro"

import Header from "./Header"
import Footer from "./Footer"
import SEO from "./seo"

const Wrapper = tw.div`
  bg-dark
  text-white
  min-w-full min-h-screen
`

export default function Layout(props) {
  const data = useStaticQuery(graphql`
    query SiteNavigation {
      site {
        siteMetadata {
          nav {
            name
            link
            subitems {
              name
              link
            }
          }
        }
      }
    }
  `)
  const { children } = props
  const items = get(data, "site.siteMetadata.nav", [])
  return (
    <Wrapper>
      <SEO />
      <Helmet>
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
        <link
          rel="icon"
          type="image/png"
          href="/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-16x16.png"
          sizes="16x16"
        />
      </Helmet>
      <Header items={items} />
      {children}
      <Footer items={items} />
    </Wrapper>
  )
}
