import React from "react"
import tw, { styled } from "twin.macro"

import get from "lodash/get"

import { graphql } from "gatsby"
import Img from "gatsby-image"

import { getPageContent } from "../util"

import Anchor from "../components/Anchor"
import ContactUsButton from "../components/ContactUsButton"
import Container from "../components/Container"
import Layout from "../components/Layout"
import Section from "../components/Section"
import ReadMoreBox from "../components/ReadMoreBox"
import Box from "../components/Box"
import P from "../components/Paragraph.js"
import SquareTitle from "../components/SquareTitle"

const CPBox = styled(Box)`
  padding-top: 80rem;
`

const KMGBox = tw(Box)`
  py-24 lg:py-48
`

const ButtonContainer = tw.div`
  pt-4
`

const RightSection = tw(Section)`
  bg-right
`

const PieRows = tw.div`
  flex flex-col items-stretch py-16
`

const PieRow = styled.div`
  ${tw`
    flex flex-row flex-wrap md:flex-no-wrap justify-center items-center my-4
  `}
  &>* {
    ${tw`w-1/2 p-0`}
  }
`

const PieImg = styled(Img)`
  max-width: 400px;
`

const SecondPieImg = tw(PieImg)`
  transform scale-95
`

const ThridPieImg = tw(PieImg)`
  transform scale-90 -translate-x-5
`

const BalanceTitle = tw.h2`
  text-primary uppercase font-bold
`

const ImgContainer = tw.div`
  w-full text-center my-4
`

const Asterisk = tw.div`
  text-white text-sm italic
`

export default function TrainWithUsPage({ data }) {
  const pageContent = getPageContent(data)

  return (
    <Layout>
      <Section
        id="pro_boxing"
        bgImage={get(data, "bgProBoxing.childImageSharp.fluid")}
        isFirst
        contentAt="left"
      >
        <ReadMoreBox
          title={pageContent.proBoxingTitle}
          shortContent={pageContent.proBoxing[0]}
          fullContent={<P>{pageContent.proBoxing}</P>}
          additionalButtons={<ContactUsButton />}
          alignBottom
        />
      </Section>
      <Section
        id="boxing"
        bgImage={get(data, "bgBoxing.childImageSharp.fluid")}
        contentAt="left"
      >
        <ReadMoreBox
          title={pageContent.boxingTitle}
          shortContent={<P>{pageContent.boxing.slice(0, 2)}</P>}
          fullContent={<P>{pageContent.boxing}</P>}
          additionalButtons={<ContactUsButton />}
          alignBottom
        />
      </Section>
      <Section
        id="kickboxing"
        bgImage={get(data, "bgKickboxing.childImageSharp.fluid")}
        contentAt="right"
        bgSize="cover"
      >
        <ReadMoreBox
          title={pageContent.kickboxingTitle}
          shortContent={<P>{pageContent.kickboxing.slice(0, 2)}</P>}
          fullContent={<P>{pageContent.kickboxing}</P>}
          additionalButtons={<ContactUsButton />}
          alignBottom
        />
      </Section>
      <Section
        bgImage={get(data, "bgCp.childImageSharp.fluid")}
        contentAt="left"
      >
        <Anchor id="combat_performance" top="60vh" />
        <CPBox>
          <Box.Title>{pageContent.cpTitle}</Box.Title>
          <Box.Content>
            <P>{pageContent.cp}</P>
            <ButtonContainer>
              <ContactUsButton />
            </ButtonContainer>
          </Box.Content>
        </CPBox>
      </Section>
      <Section
        id="krav_maga_global"
        bgImage={get(data, "bgKmg.childImageSharp.fluid")}
        contentAt="right"
      >
        <KMGBox>
          <Box.Title>{pageContent.kmgTitle}</Box.Title>
          <Box.Content>
            <P>{pageContent.kmg}</P>
            <ButtonContainer>
              <ContactUsButton />
            </ButtonContainer>
          </Box.Content>
        </KMGBox>
      </Section>
      <RightSection
        id="yoga"
        bgImage={get(data, "bgYoga.childImageSharp.fluid")}
        bgSize="contain"
        contentAt="left"
      >
        <ReadMoreBox
          title={pageContent.yogaTitle}
          shortContent={<P>{pageContent.yoga[0]}</P>}
          fullContent={<P>{pageContent.yoga}</P>}
          additionalButtons={<ContactUsButton />}
          alignBottom
        />
      </RightSection>
      <Container>
        <SquareTitle id="balanced_training">
          {pageContent.balanceTitle}
        </SquareTitle>
        <PieRows>
          <PieRow>
            <ImgContainer>
              <PieImg fluid={get(data, "pieSkills.childImageSharp.fluid")} />
            </ImgContainer>
            <Box>
              <Box.Content>
                <BalanceTitle>{pageContent.balanceSkillsTitle}</BalanceTitle>
                <P>{pageContent.balanceSkills}</P>
              </Box.Content>
            </Box>
          </PieRow>
          <PieRow>
            <ImgContainer>
              <SecondPieImg fluid={get(data, "pieCp.childImageSharp.fluid")} />
            </ImgContainer>
            <Box>
              <Box.Content>
                <BalanceTitle>{pageContent.balanceCpTitle}</BalanceTitle>
                <P>{pageContent.balanceCp}</P>
              </Box.Content>
            </Box>
          </PieRow>
          <PieRow>
            <ImgContainer>
              <ThridPieImg
                fluid={get(data, "pieLifestyle.childImageSharp.fluid")}
                fit="CONTAIN"
              />
            </ImgContainer>
            <Box>
              <Box.Content>
                <BalanceTitle>{pageContent.balanceLifestyleTitle}</BalanceTitle>
                <P>{pageContent.balanceLifestyle}</P>
              </Box.Content>
            </Box>
          </PieRow>
        </PieRows>
        <Asterisk>* {pageContent.asterisk}</Asterisk>
      </Container>
      <Section
        id="junior_boxing_and_kicking"
        bgImage={get(data, "bgJunior.childImageSharp.fluid")}
        contentAt="left"
      >
        <ReadMoreBox
          title={pageContent.juniorTitle}
          shortContent={<P>{pageContent.junior[0]}</P>}
          fullContent={<P>{pageContent.junior}</P>}
          additionalButtons={<ContactUsButton />}
          alignBottom
        />
      </Section>
      <Section
        id="team_building"
        bgImage={get(data, "bgTb.childImageSharp.fluid")}
        contentAt="left"
      >
        <ReadMoreBox
          title={pageContent.tbTitle}
          shortContent={<P>{pageContent.tb[0]}</P>}
          fullContent={<P>{pageContent.tb}</P>}
          additionalButtons={<ContactUsButton />}
          alignBottom
        />
      </Section>
      <Section
        id="become_an_aboro_coach"
        bgImage={get(data, "bgCoach.childImageSharp.fluid")}
        contentAt="left"
      >
        <Box alignBottom>
          <Box.Title>{pageContent.coachTitle}</Box.Title>
          <Box.Content>
            <P>{pageContent.coach}</P>
            <ButtonContainer>
              <ContactUsButton />
            </ButtonContainer>
          </Box.Content>
        </Box>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TrainWithUsPage($path: String) {
    bgProBoxing: file(relativePath: { eq: "images/bg_train_pro_boxing.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgBoxing: file(relativePath: { eq: "images/bg_train_boxing.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgKickboxing: file(relativePath: { eq: "images/bg_train_kickboxing.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgKmg: file(relativePath: { eq: "images/bg_train_kmg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgJunior: file(relativePath: { eq: "images/bg_train_junior.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgCp: file(relativePath: { eq: "images/bg_train_cp.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgTb: file(relativePath: { eq: "images/bg_train_tb.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgYoga: file(relativePath: { eq: "images/bg_train_yoga.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgCoach: file(relativePath: { eq: "images/bg_train_coach.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pieSkills: file(relativePath: { eq: "images/pie_skills.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pieCp: file(relativePath: { eq: "images/pie_cp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pieLifestyle: file(relativePath: { eq: "images/pie_lifestyle.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sitePage(path: { eq: $path }) {
      contentFile {
        childContentJson {
          proBoxingTitle
          proBoxing
          boxingTitle
          boxing
          kickboxingTitle
          kickboxing
          cpTitle
          cp
          kmgTitle
          kmg
          yogaTitle
          yoga
          balanceTitle
          balanceSkillsTitle
          balanceSkills
          balanceCpTitle
          balanceCp
          balanceLifestyleTitle
          balanceLifestyle
          asterisk
          juniorTitle
          junior
          tbTitle
          tb
          coachTitle
          coach
        }
      }
    }
  }
`
