import React from "react"
import tw, { styled } from "twin.macro"

import get from "lodash/get"

import { graphql } from "gatsby"
import Img from "gatsby-image"

import { getPageContent } from "../util"

import Box from "../components/Box"
import ContactUsButton from "../components/ContactUsButton"
import P from "../components/Paragraph"
import Layout from "../components/Layout"
import Section from "../components/Section"
import ReadMoreBox from "../components/ReadMoreBox"

const MethodSection = tw.section`
  pt-48
  px-12 lg:px-24
  pb-32
`

const Container = tw.div`
  container mx-auto
`

const MethodBoxesContainer = tw.div`
  flex
  flex-row
  flex-wrap
`

const MethodBox = styled(ReadMoreBox)`
  ${tw`
    w-full lg:w-1/2 p-4
  `}
`

const StyledImage = tw(Img)`
  w-full
  mb-32
`

const RankingSection = tw(Section)`
  lg:px-32
`

const CUBContainer = tw.div`
  pt-4
`

export default function AboroMethodPage(props) {
  const { data } = props
  const aboroMethod = get(data, "aboroMethod.childImageSharp.fluid")
  const aboroRanking = get(data, "aboroRanking.childImageSharp.fluid")
  const bgRanking = get(data, "rankingBackground.childImageSharp.fluid")
  const pageContent = getPageContent(data)

  const whatItDoesShort = (<P>{[
    pageContent.aboroMethodDescription[0],
    "{emptyLine}"
  ]}</P>)
  const whatItIsShort = (<P>{pageContent.whatItIsDescription[0]}</P>)


  return (
    <Layout>
      <MethodSection>
        <Container>
          <StyledImage fluid={aboroMethod} id="aboro_method" />
          <MethodBoxesContainer>
            <MethodBox
              title={pageContent.whatItIsTitle}
              shortContent={whatItIsShort}
              fullContent={<P>{pageContent.whatItIsDescription}</P>}
            />
            <MethodBox
              title={pageContent.whatItDoesTitle}
              shortContent={whatItDoesShort}
              fullContent={
                <>
                  <P>{pageContent.aboroMethodDescription}</P>
                </>
              }
            />
          </MethodBoxesContainer>
        </Container>
      </MethodSection>
      <RankingSection bgImage={bgRanking} blackFilter>
        <Container>
          <StyledImage fluid={aboroRanking} id="aboro_ranking" />
          <Box>
            <Box.Title>{pageContent.rankingTitle}</Box.Title>
            <Box.Content>
              <P>{pageContent.rankingDescription}</P>
              <CUBContainer>
                <ContactUsButton />
              </CUBContainer>
            </Box.Content>
          </Box>
        </Container>
      </RankingSection>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboroMethodPage($path: String) {
    aboroMethod: file(relativePath: { eq: "images/aboro_method.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    aboroRanking: file(relativePath: { eq: "images/aboro_ranking.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    rankingBackground: file(relativePath: { eq: "images/bg_ranking.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sitePage(path: { eq: $path }) {
      contentFile {
        childContentJson {
          aboroMethodSubtitle
          aboroMethodDescription

          whatItDoesTitle
          whatItIsTitle
          whatItIsDescription

          rankingTitle
          rankingDescription
        }
      }
    }
  }
`
