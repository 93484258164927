import tw, { styled } from "twin.macro"

export default styled.h1`
  ${tw`
    text-sm lg:text-3xl font-bold
  `}
  ${({ primary, normalCase }) => [
    primary && tw`text-primary`,
    !normalCase && tw`uppercase`,
  ]}
`
