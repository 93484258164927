import React from "react"

import { FormattedMessage } from "gatsby-plugin-intl"

import Button from "../components/Button"

export default ({ children }) => (
  <Button to="/contact_us">
    {children}
    {!children && <FormattedMessage id="contact_us" />}
  </Button>
)
