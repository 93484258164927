import React from "react"
import tw from "twin.macro"

const Container = tw.div`
  fixed inset-0 bg-dark bg-opacity-75
  overflow-auto
`

export default function Modal(props) {
  const { open, onClose, children } = props
  if (!open) {
    return null
  }

  return <Container onClick={onClose}>{children}</Container>
}
