import React, { useState } from "react"
import tw, { styled } from "twin.macro"

import take from "lodash/take"
import takeRight from "lodash/takeRight"
import useScrollPosition from "@react-hook/window-scroll"

import MenuItem from "./MenuItem"
import { Bars } from "./Icons"
import Logo from "./Logo"
import LangSwitch from "./LangSwitch"

const Hamburger = tw.div`cursor-pointer transform transition-transform duration-100`

const Navbar = styled.nav`
  ${tw`
    fixed z-10
    w-full
    py-4
    flex flex-col lg:flex-row
    justify-center items-stretch
  `}
  ${({ scrollDown }) => (scrollDown ? tw`bg-dark` : tw`bg-dark lg:bg-transparent`)}
  ${MenuItem} {
    ${({ expanded }) => !expanded && tw`hidden lg:inline-block`}
  }
  ${Hamburger} {
    ${({ expanded }) => expanded && tw`rotate-90`}
  }
`

const BigLogo = tw.div`
  lg:inline-block w-32
  hidden
`
const HalfMenuItems = tw.div`
  inline-flex items-center justify-around
  flex-col lg:flex-row
  lg:w-5/12
`

const MobileMenu = tw.div`
  w-full
  px-8
  lg:hidden
  flex flex-row justify-between items-center
`

const SmallLogo = styled.div`
  ${tw`w-16 ml-5`}
  & img {
    padding: 1px;
  }
`

const StyledLangSwitch = tw(LangSwitch)`
  absolute right-0 top-0 pr-4 pt-4 hidden lg:block
`

const MobileLangSwitch = tw(LangSwitch)`
  lg:hidden
`

export default function Header(props) {
  const { items } = props
  const midPoint = Math.floor(items.length / 2)

  const [expanded, setExpanded] = useState(false)
  const scrollY = useScrollPosition()

  return (
    <Navbar expanded={expanded} scrollDown={scrollY >= 60} >
      <MobileMenu>
        <Hamburger
          onClick={() => {
            setExpanded(!expanded)
          }}
        >
          <Bars />
        </Hamburger>
        <SmallLogo>
          <Logo />
        </SmallLogo>
        <MobileLangSwitch />
      </MobileMenu>
      <HalfMenuItems>
        {take(items, midPoint).map((i, idx) => (
          <MenuItem key={`left-${idx}`} item={i} />
        ))}
      </HalfMenuItems>
      <BigLogo>
        <Logo />
      </BigLogo>
      <HalfMenuItems>
        {takeRight(items, items.length - midPoint).map((i, idx) => (
          <MenuItem key={`right-${idx}`} item={i} />
        ))}
      </HalfMenuItems>
      <StyledLangSwitch />
    </Navbar>
  )
}
