import React, { useState } from "react"
import tw, { styled } from "twin.macro"

import isFunction from "lodash/isFunction"

import Title from "../components/Title"

const BoxTitle = tw(Title)`
  block bg-primary text-left text-white px-4 py-2 mb-2
`

const BoxContent = tw.div`
  block box-border border-4 border-primary p-4
  bg-black bg-opacity-75
`

const Container = styled.div`
  ${tw`
    flex flex-col items-stretch
    w-full
    transition-all duration-150 ease-out
  `}
  ${({ expanded, alignBottom }) =>
    !expanded &&
    alignBottom &&
    `
      padding-top: 45vh;
    `}
`

export default function Box(props) {
  const { children, onExpandingEnd, ...rest } = props
  const [expanded, setExpanded] = useState(false)

  const content = isFunction(children)
    ? children({ expanded, setExpanded })
    : children

  const handleTransitionEnd = () => {
    setTimeout(onExpandingEnd, 100)
  }

  return (
    <Container
      expanded={expanded}
      onTransitionEnd={handleTransitionEnd}
      {...rest}
    >
      {content}
    </Container>
  )
}

Box.Title = BoxTitle
Box.Content = BoxContent
