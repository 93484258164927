import React from "react"
import tw from "twin.macro"

import { changeLocale } from "gatsby-plugin-intl"

const Container = tw.div`
  text-xs font-bold
`

const Switch = tw.span`
  cursor-pointer hover:text-primary
`

export default function LangSwitch(props) {
  return (
    <Container {...props}>
      <Switch onClick={() => changeLocale("zh-cn")}>中</Switch> |{" "}
      <Switch onClick={() => changeLocale("en")}>EN</Switch>
    </Container>
  )
}
