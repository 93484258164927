import React from "react"
import tw, { styled } from "twin.macro"
import isEmpty from "lodash/isEmpty"
import { Link, FormattedMessage } from "gatsby-plugin-intl"

import SubMenu from "./SubMenu"

const MenuText = tw.span`
  border-b-4 border-solid border-transparent
  pb-1
`

export default styled(MenuItem)`
  ${tw`
    font-bold text-sm py-2 lg:text-xs
    uppercase
    relative
  `}

  &:hover {
    ${SubMenu} {
      ${tw`
        lg:block
      `}
    }
  }

  ${MenuText} {
    ${tw`hover:text-primary lg:hover:border-primary`}
  }

  ${SubMenu} {
    ${tw`
      hidden
      absolute
    `}
  }
`

function MenuItem(props) {
  const {
    item: { link, name, subitems },
    ...rest
  } = props

  return (
    <div {...rest}>
      <MenuText>
        <Link to={link}>
          <FormattedMessage id={name} />
        </Link>
      </MenuText>
      {!isEmpty(subitems) && <SubMenu items={subitems} />}
    </div>
  )
}
