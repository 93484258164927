const values = require("lodash/values")
const isArray = require("lodash/isArray")
const flatten = require("lodash/flatten")
const filter = require("lodash/filter")

const trimSrcPrefix = (s) => (s.startsWith('/src/') ? s.slice(5) : s);

exports.trimSrcPrefix = trimSrcPrefix;

exports.extractImagePaths = function extractImagePaths(val) {
  if (typeof val === "string" && /\.(png|jpeg|jpg|webp)$/.test(val)) {
    return [trimSrcPrefix(val)]
  }

  if (isArray(val) || typeof val === "object") {
    return filter(flatten(values(val).map(extractImagePaths)))
  }

  return []
}
