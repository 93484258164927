import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faSquare } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faWeibo,
} from "@fortawesome/free-brands-svg-icons"

export const Bars = (props) => (
  <FontAwesomeIcon icon={faBars} size="lg" {...props} />
)

export const Facebook = (props) => (
  <FontAwesomeIcon icon={faFacebookSquare} size="2x" {...props} />
)

export const Instagram = (props) => (
  <FontAwesomeIcon icon={faInstagramSquare} size="2x" {...props} />
)

export const Linkedin = (props) => (
  <FontAwesomeIcon icon={faLinkedin} size="2x" {...props} />
)

export const Weibo = (props) => (
  <FontAwesomeIcon
    icon={faWeibo}
    mask={faSquare}
    transform="shrink-6"
    size="2x"
    {...props}
  />
)
