import React from "react"
import tw from "twin.macro"
import { Link, FormattedMessage } from "gatsby-plugin-intl"

const ItemContainer = tw.div`
  border-2 border-solid border-primary
  bg-black bg-opacity-75
  flex
  flex-col
  text-left
`

const Item = tw(Link)`
  p-1
  hover:text-primary
  whitespace-no-wrap
`

export default tw(SubMenu)`
  pt-2
`

function SubMenu(props) {
  const { items, ...rest } = props
  return (
    <div {...rest}>
      <ItemContainer>
        {items.map((item) => (
          <Item key={item.name} to={item.link}>
            <FormattedMessage id={item.name} />
          </Item>
        ))}
      </ItemContainer>
    </div>
  )
}
