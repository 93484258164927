import React from "react"

import tw, { styled } from "twin.macro"
import { useIntl } from "gatsby-plugin-intl"

const Form = tw.form`
  text-center
`

const Input = styled.input`
  ${tw`
    block w-full
    border-2 border-primary
    bg-black bg-opacity-50 px-4 py-2 my-2
    outline-none
  `}

  ::placeholder {
    ${tw`text-white opacity-100`}
  }
`

const Hidden = tw.div`
  hidden
`

const Textarea = Input.withComponent("textarea")

const SubmitButton = tw(Input)`
  inline-block w-1/3 text-primary cursor-pointer
`

export default function ContactForm() {
  const { locale, formatMessage } = useIntl()
  return (
    <Form
      name="contact"
      method="POST"
      action={`/${locale}/form_success`}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <Hidden>
        <label>
          Don’t fill this out: <input name="bot-field" />
        </label>
      </Hidden>

      <Input
        type="text"
        name="name"
        id="name"
        placeholder={formatMessage({ id: "contact_name_placeholder" })}
      />
      <Input
        type="email"
        name="email"
        id="email"
        placeholder={formatMessage({ id: "contact_email_placeholder" })}
        required
      />
      <Textarea
        type="text"
        name="message"
        id="message"
        placeholder={formatMessage({ id: "contact_message_placeholder" })}
        required
        rows="5"
      />
      <SubmitButton
        type="submit"
        value={formatMessage({ id: "contact_submit" })}
      />
    </Form>
  )
}
