import React from "react"
import tw from "twin.macro"

import { graphql } from "gatsby"

import { getPageContent } from "../util"
import Box from "../components/Box"

import Layout from "../components/Layout"

const MessageBox = tw(Box)`
  p-16 lg:py-32
`

export default function SuccessPage({ data }) {
  const pageContent = getPageContent(data)

  return (
    <Layout>
      <MessageBox>
        <Box.Content>{pageContent.message}</Box.Content>
      </MessageBox>
    </Layout>
  )
}

export const pageQuery = graphql`
  query NotFoundPage($path: String) {
    sitePage(path: { eq: $path }) {
      contentFile {
        childContentJson {
          message
        }
      }
    }
  }
`
