import React from "react"
import tw from "twin.macro"

import { Link } from "gatsby-plugin-intl"

const StyledButton = tw.div`
  inline-block border-4 border-primary
  text-sm text-primary
  hover:(text-white border-white)
  active:(text-primary border-primary)
  cursor-pointer
  py-2 px-2 lg:px-8
`

export default function LinkButton(props) {
  const { children, to, onClick } = props

  if (to) {
    return (
      <Link to={to}>
        <StyledButton>{children}</StyledButton>
      </Link>
    )
  }

  return <StyledButton onClick={onClick}>{children}</StyledButton>
}
