import React from "react"
import tw from "twin.macro"

import { Link, FormattedMessage } from "gatsby-plugin-intl"

const Bold = tw.span`
  font-bold
`

const Primary = tw.span`
  text-primary
`

const Upper = tw.span`
  uppercase
`

const Italic = tw.span`
  italic
`

const formats = {
  b: (chunks) => <Bold>{chunks}</Bold>,
  pt: (chunks) => <Primary>{chunks}</Primary>,
  emptyLine: <br />,
  up: (chunks) => <Upper>{chunks}</Upper>,
  i: (chunks) => <Italic>{chunks}</Italic>,
  a: (chunks) => {
    const [label, ...linkParts] = chunks.split("|")
    const link = linkParts.join("|")
    return <Link to={link}>{label}</Link>
  },
}

export default function WrappedFM(props) {
  const { values, children, className, ...rest } = props
  return (
    <p className={className}>
      <FormattedMessage
        id="none_exist"
        {...rest}
        values={{ ...values, ...formats }}
        defaultMessage={children}
      />
    </p>
  )
}
