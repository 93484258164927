import React from "react"
import tw from "twin.macro"

import { graphql } from "gatsby"
import Img from "gatsby-image"

import { getPageContent } from "../util"
import Container from "../components/Container"
import Layout from "../components/Layout"
import SquareTitle from "../components/SquareTitle"

const ClassSchedule = tw(Img)`
  w-full object-contain mt-4
`

const FullTitle = tw(SquareTitle)`
  w-full
`

export default function ClassSchedulePage({ data }) {
  const pageContent = getPageContent(data)

  return (
    <Layout>
      <Container>
        <FullTitle>{pageContent.title}</FullTitle>
        <ClassSchedule fluid={pageContent.classSchedule} />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ClassSchedulePage($path: String) {
    sitePage(path: { eq: $path }) {
      contentFile {
        childContentJson {
          title
          classSchedule

          allImageFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            relativePath
          }
        }
      }
    }
  }
`
