import React from "react"
import tw from "twin.macro"

import get from "lodash/get"

import { graphql } from "gatsby"

import { getPageContent } from "../util"

import Layout from "../components/Layout"
import Section from "../components/Section"
import ReadMoreBox from "../components/ReadMoreBox"
import ContactUsButton from "../components/ContactUsButton"
import Box from "../components/Box"
import P from "../components/Paragraph.js"

const ButtonContainer = tw.div`
  pt-4
`

export default function TrainingCampsPage({ data }) {
  const pageContent = getPageContent(data)

  return (
    <Layout>
      <Section
        id="ako"
        bgImage={get(data, "bgAko.childImageSharp.fluid")}
        isFirst
        contentAt="right"
      >
        <Box alignBottom>
          <Box.Title>{pageContent.akoTitle}</Box.Title>
          <Box.Content>
            <P>{pageContent.ako}</P>
            <ButtonContainer>
              <ContactUsButton />
            </ButtonContainer>
          </Box.Content>
        </Box>
      </Section>
      <Section
        id="accc"
        bgImage={get(data, "bgAccc.childImageSharp.fluid")}
        isFirst
        contentAt="left"
      >
        <ReadMoreBox
          title={pageContent.acccTitle}
          shortContent={<P>{pageContent.accc[0]}</P>}
          fullContent={<P>{pageContent.accc}</P>}
          additionalButtons={<ContactUsButton />}
          alignBottom
        />
      </Section>
      <Section
        id="junior_camp"
        bgImage={get(data, "bgJuniorCamp.childImageSharp.fluid")}
        isFirst
        contentAt="right"
      >
        <ReadMoreBox
          title={pageContent.juniorCampTitle}
          shortContent={<P>{pageContent.juniorCamp[0]}</P>}
          fullContent={<P>{pageContent.juniorCamp}</P>}
          additionalButtons={<ContactUsButton />}
          alignBottom
        />
      </Section>
    </Layout>
  )
}
export const pageQuery = graphql`
  query TrainingCampsQuery($path: String) {
    bgAko: file(relativePath: { eq: "images/bg_ako.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgAccc: file(relativePath: { eq: "images/bg_accc.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgJuniorCamp: file(relativePath: { eq: "images/bg_junior_camp.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sitePage(path: { eq: $path }) {
      contentFile {
        childContentJson {
          akoTitle
          ako
          acccTitle
          accc
          juniorCampTitle
          juniorCamp
        }
      }
    }
  }
`
