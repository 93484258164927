import React, { useState } from "react"
import tw, { styled } from "twin.macro"
import range from "lodash/range"

import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import P from "./Paragraph"
import Modal from "./Modal"
import Title from "./Title"

const CardContainer = tw.div`
  flex flex-row p-4
  hover:cursor-pointer
  transform hover:scale-105
`

const Card = styled.div`
  width: 22rem;
  min-height: 1rem;
  ${tw`flex flex-col justify-start`}
`

const Picture = tw(Img)`
  border-4 border-primary bg-dark bg-opacity-75 mb-2
`

const Bar = tw.div`
  text-base text-black bg-primary px-4 py-2 mb-2
`

const NameBar = tw(Bar)`
  uppercase font-bold
`

const ExpertiseBar = tw(Bar)`
  flex-grow
`

function Staff(props) {
  const { name, expertise, picture, onClick } = props

  return (
    <CardContainer onClick={onClick}>
      <Card>
        <Picture fluid={picture} />
        <NameBar>{name}</NameBar>
        <ExpertiseBar>{expertise}</ExpertiseBar>
      </Card>
    </CardContainer>
  )
}

const DetailBox = styled(BackgroundImage)`
  max-width: 500px;
  height: 708px;
  top: 50%;
  ${tw`
    m-auto
    bg-gray-500
    border-2 border-primary
    transform scale-75 sm:scale-100 -translate-y-1/2
    flex flex-col justify-end items-stretch p-4
  `}
`

const DetailContent = tw.div`
  bg-dark bg-opacity-75
  text-xs text-white p-4
`

const DetailName = tw(Title)`
  inline-block bg-primary text-white pl-4 pr-16 mb-2
`

const DetailStaff = (props) => {
  const { name, detailPicture, description } = props
  return (
    <DetailBox fluid={detailPicture}>
      <DetailContent>
        <DetailName>{name}</DetailName>
        <P>{description}</P>
      </DetailContent>
    </DetailBox>
  )
}

const RosterContainer = styled.div`
  ${tw`flex flex-row py-8 justify-center flex-wrap mx-auto items-stretch mx-auto`}
  max-width: 1280px;
`

export default function Roster(props) {
  const { coaches } = props
  const [detailIdx, setDetail] = useState(null)
  const emptyCards = range(3 - (coaches.length % 3)).map((idx) => (
    <CardContainer key={`empty-${idx}`}>
      <Card />
    </CardContainer>
  ))

  return (
    <RosterContainer>
      {coaches.map((c, idx) => (
        <Staff key={c.name + idx} onClick={() => setDetail(idx)} {...c} />
      ))}
      {emptyCards}
      <Modal
        open={detailIdx !== null}
        onClose={() => {
          setDetail(null)
        }}
      >
        <DetailStaff
          {...(coaches[detailIdx] || {})}
          onClose={() => setDetail(null)}
        />
      </Modal>
    </RosterContainer>
  )
}
