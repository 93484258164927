// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboro-method-js": () => import("./../../../src/pages/aboro_method.js" /* webpackChunkName: "component---src-pages-aboro-method-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-class-schedule-js": () => import("./../../../src/pages/class_schedule.js" /* webpackChunkName: "component---src-pages-class-schedule-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact_us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-empower-js": () => import("./../../../src/pages/empower.js" /* webpackChunkName: "component---src-pages-empower-js" */),
  "component---src-pages-form-success-js": () => import("./../../../src/pages/form_success.js" /* webpackChunkName: "component---src-pages-form-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-train-with-us-js": () => import("./../../../src/pages/train_with_us.js" /* webpackChunkName: "component---src-pages-train-with-us-js" */),
  "component---src-pages-training-camps-js": () => import("./../../../src/pages/training_camps.js" /* webpackChunkName: "component---src-pages-training-camps-js" */)
}

