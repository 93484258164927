import React from "react"
import tw, { styled } from "twin.macro"

import get from "lodash/get"

import { graphql } from "gatsby"

import { getPageContent } from "../util"

import Anchor from "../components/Anchor"
import ContactUsButton from "../components/ContactUsButton"
import Layout from "../components/Layout"
import Section from "../components/Section"
import ReadMoreBox from "../components/ReadMoreBox"
import Box from "../components/Box"
import Title from "../components/Title"
import Roster from "../components/Roster"
import P from "../components/Paragraph.js"

const QuoteContainer = tw.div`
  pb-2
`

const Quote = tw.div`
  italic
`

const QuoteBy = tw.div`
  text-right text-sm font-bold
`

const FoundersBox = styled(Box)`
  padding-top: 70rem;
`

const FoundersInfo = tw.div`
  w-full flex flex-row flex-wrap
`

const FounderColumn = tw.div`
  w-full lg:w-1/2
`

const LeftColumn = tw(FounderColumn)`
  lg:pr-8
`

const RightColumn = tw(FounderColumn)`
  mt-8
  lg:pl-8 lg:mt-0
`

const JobTitle = tw.div`
  italic
  mb-4
`

const CoachesBox = styled(Box)`
  padding-top: 40rem;
  ${tw`lg:w-3/4`}
`

const LongBgSection = styled(Section)`
  min-height: 200vh;
`

export default function AboutPage({ data }) {
  const bgAboroAcademy = get(data, "bgAboroAcademy.childImageSharp.fluid")
  const bgAboroFoundation = get(data, "bgAboroFoundation.childImageSharp.fluid")
  const bgFounders = get(data, "bgFounders.childImageSharp.fluid")
  const bgCoaches = get(data, "bgCoaches.childImageSharp.fluid")
  const pageContent = getPageContent(data)

  const aboroFoundationQuote = (
    <QuoteContainer>
      <Quote>{pageContent.aboroFoundationQuote}</Quote>
      <QuoteBy>{pageContent.aboroFoundationQuoteBy}</QuoteBy>
    </QuoteContainer>
  )

  const aboroFoundationFull = (
    <>
      {aboroFoundationQuote}
      <P>{pageContent.aboroFoundation}</P>
    </>
  )

  return (
    <Layout>
      <Section bgImage={bgAboroAcademy} isFirst contentAt="left">
        <ReadMoreBox
          id="aboro_academy"
          title={pageContent.aboroAcademyTitle}
          shortContent={pageContent.aboroAcademy[0]}
          fullContent={<P>{pageContent.aboroAcademy}</P>}
          additionalButtons={<ContactUsButton />}
          alignBottom
        />
      </Section>
      <Section bgImage={bgAboroFoundation} contentAt="right">
        <ReadMoreBox
          id="aboro_foundation"
          title={pageContent.aboroFoundationTitle}
          shortContent={aboroFoundationQuote}
          fullContent={aboroFoundationFull}
          additionalButtons={<ContactUsButton />}
          alignBottom
        />
      </Section>
      <LongBgSection bgImage={bgFounders}>
        <Anchor id="founders" top="60vh" />
        <FoundersBox>
          <Box.Title>{pageContent.foundersTitle}</Box.Title>
          <Box.Content>
            <FoundersInfo>
              <LeftColumn>
                <Title normalCase primary>
                  {pageContent.micheleFullname}
                </Title>
                <JobTitle>{pageContent.micheleTitle}</JobTitle>
                <P>{pageContent.micheleBio}</P>
              </LeftColumn>
              <RightColumn>
                <Title normalCase primary>
                  {pageContent.mascaFullname}
                </Title>
                <JobTitle>{pageContent.mascaTitle}</JobTitle>
                <P>{pageContent.mascaBio}</P>
              </RightColumn>
            </FoundersInfo>
          </Box.Content>
        </FoundersBox>
      </LongBgSection>
      <Section bgImage={bgCoaches} bgSize="contain" >
        <CoachesBox>
          <Anchor id="team_of_coaches" top="40vh" />
          <Box.Title>{pageContent.coachesTitleFirstLine}</Box.Title>
          <Box.Title>{pageContent.coachesTitleSecondLine}</Box.Title>
          <Box.Content>
            <P>{pageContent.coaches}</P>
          </Box.Content>
        </CoachesBox>
        <Roster coaches={pageContent.roster} />
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPage($path: String) {
    bgAboroAcademy: file(relativePath: { eq: "images/bg_4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgAboroFoundation: file(relativePath: { eq: "images/bg_5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgFounders: file(relativePath: { eq: "images/founders.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bgCoaches: file(relativePath: { eq: "images/bg_coaches.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sitePage(path: { eq: $path }) {
      contentFile {
        childContentJson {
          aboroAcademyTitle
          aboroAcademy
          aboroFoundationTitle
          aboroFoundationQuote
          aboroFoundationQuoteBy
          aboroFoundation
          foundersTitle
          micheleFullname
          micheleTitle
          micheleBio
          mascaFullname
          mascaTitle
          mascaBio

          coachesTitleFirstLine
          coachesTitleSecondLine
          coaches

          roster {
            name
            expertise
            picture
            detailPicture
            description
          }

          allImageFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            relativePath
          }
        }
      }
    }
  }
`
