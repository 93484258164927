import React, { useState } from "react"
import tw, { styled } from "twin.macro"
import isArray from "lodash/isArray"

import { FormattedMessage } from "gatsby-plugin-intl"

import Button from "../components/Button"
import Box from "../components/Box"

const ButtonContainer = styled.div`
  ${tw`pt-4 flex flex-row`}
  &>* {
    ${tw`mr-2`}
  }
`

export default function ReadMoreBox(props) {
  const [inTransition, setTransition] = useState(false)
  const {
    title,
    shortContent,
    fullContent,
    additionalButtons,
    alignBottom,
    ...rest
  } = props
  const titleSection = isArray(title) ? (
    title.map((t, idx) => <Box.Title key={idx}>{t}</Box.Title>)
  ) : (
    <Box.Title>{title}</Box.Title>
  )

  return (
    <Box
      onExpandingEnd={() => {
        setTransition(false)
      }}
      alignBottom={alignBottom}
      {...rest}
    >
      {({ expanded, setExpanded }) => (
        <>
          {titleSection}
          <Box.Content>
            {expanded && !inTransition ? fullContent : shortContent}
            <ButtonContainer>
              <Button
                onClick={() => {
                  if (alignBottom) {
                    setTransition(true)
                  }
                  setExpanded(!expanded)
                }}
              >
                {expanded ? (
                  <FormattedMessage id="read_more_expanded" />
                ) : (
                  <FormattedMessage id="read_more_collapsed" />
                )}
              </Button>
              {expanded && additionalButtons}
            </ButtonContainer>
          </Box.Content>
        </>
      )}
    </Box>
  )
}
