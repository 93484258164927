import React from "react"
import tw, { styled } from "twin.macro"

import { graphql } from "gatsby"

import get from "lodash/get"
import last from "lodash/last"

import Player from "react-player"

import Layout from "../components/Layout"
import Section from "../components/Section"
import SquareTitle from "../components/SquareTitle"
import { getPageContent } from "../util"

const MediaContainer = tw.div`
  w-full flex flex-col items-stretch mt-32 p-4 lg:p-32
`

const VideoPlayer = tw(Player)`
  absolute inset-0
`

const VideoCell = styled.div`
  ${tw`
    relative
    inline-block border-2 border-primary
  `}
  ${({ half }) => [
    half ? `width: 49%; padding-top: 27.13%;` : `width: 100%; padding-top: 56.25%;`,
  ]}
`

const Row = tw.div`
  w-full mt-4 flex flex-row justify-between
`

function Video(props) {
  const { half, url, thumbnail } = props

  return (
    <VideoCell half={half}>
      <VideoPlayer url={url} controls light={thumbnail.src} width="100%" height="100%" />
    </VideoCell>
  )
}

export default function MediaPage({ data }) {
  const pageContent = getPageContent(data)
  const { title, mainVideo, videos } = pageContent

  const videoRows = (videos || []).reduce(
    (accum, curr, idx) => {
      if (idx % 2 === 0) {
        accum.push([])
      }
      const currentRow = last(accum)
      currentRow.push(<Video half {...curr} key={idx} />)
      return accum
    },
    []
  )

  return (
    <Layout>
      <Section
        bgImage={get(data, "bgMedia.childImageSharp.fluid")}
        bgSize="cover"
        blackFilter
      >
        <MediaContainer>
          <SquareTitle>{title}</SquareTitle>
          <Row>
            <Video {...mainVideo} />
          </Row>
          {videoRows.map((row, idx) => (
            <Row key={idx}>{row}</Row>
          ))}
        </MediaContainer>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MediaQuery($path: String) {
    bgMedia: file(relativePath: { eq: "images/bg_media.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sitePage(path: { eq: $path }) {
      contentFile {
        childContentJson {
          title
          mainVideo {
            url
            thumbnail
          }

          videos {
            url
            thumbnail
          }

          allImageFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            relativePath
          }
        }
      }
    }
  }
`
