import get from "lodash/get"
import forEach from "lodash/forEach"
import isObject from "lodash/isObject"
import isString from "lodash/isString"
import find from "lodash/find"
import { trimSrcPrefix } from "../utils"

const excludedKeys = [
  'allImageFile'
];
const attachImageFluid = (data, allImages = []) => {
  forEach(data, (val, key) => {
    if (excludedKeys.indexOf(key) !== -1) {
      return;
    }
    if (isObject(val)) {
      data[key] = attachImageFluid(val, allImages)
      return
    }

    if (!isString(val)) {
      return;
    }
    const imageFluid = find(allImages, { relativePath: trimSrcPrefix(val) })
    if (imageFluid) {
      data[key] = imageFluid.childImageSharp.fluid
    }
    return
  })
  return data
}

export const getPageContent = (data) =>
  attachImageFluid(
    get(data, "sitePage.contentFile.childContentJson", {}),
    get(data, "sitePage.contentFile.childContentJson.allImageFile")
  )
