import React from "react"
import isArray from "lodash/isArray"

import FormattedMessage from "./FormattedMessage"

export default function Paragraph(props) {
  const { children, ...rest } = props
  if (isArray(children)) {
    return (
      <>
        {children.map((part, idx) => (
          <FormattedMessage {...rest} key={idx}>
            {part}
          </FormattedMessage>
        ))}
      </>
    )
  }

  return <FormattedMessage {...rest}>{children}</FormattedMessage>
}
