import React from "react"
import get from "lodash/get"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import { Link } from "gatsby-plugin-intl"

export default function Logo() {
  const data = useStaticQuery(graphql`
    query Logo {
      file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const logo = get(data, "file.childImageSharp.fluid")

  return (
    <Link to="/">
      <Img fluid={logo} />
    </Link>
  )
}
