import React from "react"
import tw, { styled } from "twin.macro"
import BackgroundImage from "gatsby-background-image"

const StyledBackgroundImage = styled(BackgroundImage)`
  ${tw`
    w-full min-h-screen
    bg-no-repeat bg-top bg-cover
  `}

  ${({ hasVideo, bgSize, overflow }) => [
    hasVideo && tw`h-screen`,
    bgSize === "contain" && tw`bg-contain`,
  ]}
`

const VideoBackground = tw.video`
  absolute object-cover w-full h-full
  z-0
`

const BlackScreen = styled.div`
  ${tw`
    absolute inset-0
    z-0
    bg-gradient-to-t from-dark to-dark
  `}
  ${({ blackFilter }) => [
    blackFilter ? tw`via-darkt` : tw`via-transparent`
  ]}
`

const Content = styled.div`
  ${tw`
    relative z-20 w-full min-h-screen py-16 px-4 lg:px-16
  `}

  ${({ isFirst }) => isFirst && tw`pt-24`}
  ${({ contentAt }) => [
    contentAt === "right" &&
      tw`
      ml-auto lg:w-1/2
    `,
    contentAt === "left" &&
      tw`
      mr-auto lg:w-1/2
    `,
  ]}
`

export default function Section(props) {
  const {
    bgImage,
    bgVideo,
    children,
    isFirst,
    blackFilter,
    contentAt,
    ...rest
  } = props

  const hasVideo = !!bgVideo

  return (
    <StyledBackgroundImage fluid={bgImage} hasVideo={hasVideo} {...rest}>
      {hasVideo && (
        <VideoBackground autoPlay muted loop playsInline>
          {bgVideo.map(({ source, type }) =>(
            <source src={source} type={`video/${type}`} key={source} />
          ))}
        </VideoBackground>
      )}
      <BlackScreen blackFilter={blackFilter} />
      <Content isFirst={isFirst} contentAt={contentAt}>
        {children}
      </Content>
    </StyledBackgroundImage>
  )
}

Section.defaultProps = {
  isFirst: false,
  blackFilter: false,
  contentAt: null,
}
