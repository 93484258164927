import React from "react"
import tw, { styled } from "twin.macro"

import get from "lodash/get"

import { graphql } from "gatsby"

import { getPageContent } from "../util"

import P from "../components/Paragraph"
import Layout from "../components/Layout"
import Section from "../components/Section"
import Box from "../components/Box"
import ContactUsButton from "../components/ContactUsButton"

const Subtitle = tw.h2`
  py-2
  text-base
  font-bold
`

const EmpowerBox = styled(Box)`
  padding-top: 150vh;
`

const CTAContainer = tw.div`
  mt-4
`

export default function AboroMethodPage(props) {
  const { data } = props
  const bgEmpower = get(data, "empowerBackground.childImageSharp.fluid")
  const pageContent = getPageContent(data)

  return (
    <Layout>
      <Section bgImage={bgEmpower} contentAt="right" isFirst bgSize="contain">
        <EmpowerBox>
          <Box.Title>{pageContent.empowerTitle}</Box.Title>
          <Box.Content>
            <Subtitle>{pageContent.empowerSubtitle}</Subtitle>
            <P>{pageContent.empowerDescrpition}</P>
            <CTAContainer>
              <ContactUsButton>{pageContent.cta}</ContactUsButton>
            </CTAContainer>
          </Box.Content>
        </EmpowerBox>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query EmpowerPage($path: String) {
    empowerBackground: file(relativePath: { eq: "images/bg_empower.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sitePage(path: { eq: $path }) {
      contentFile {
        childContentJson {
          empowerTitle
          empowerSubtitle
          empowerDescrpition
          cta
        }
      }
    }
  }
`
